<template>
  <div id="customerDetailsPage">
    <!-- Header -->
    <el-card class="headerSection" shadow="never">
      <div slot="header" class="clearfix">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="customerBreadcrumb">
          <el-breadcrumb-item to="/customer">Customers</el-breadcrumb-item>
          <el-breadcrumb-item v-if="!fetchingData">
            <span v-if="customerDetails.name && customerDetails.name.trim()">{{ customerDetails.name }}</span>
            <span v-else>{{ customerDetails.id.toUpperCase() }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
        <div style="float: right" v-if="customerDetails">
          <el-dropdown @command="onCustomerAction">
            <i class="el-icon-more"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="email-unsubscribe">
                <i class="fa-regular fa-envelope text-primary-600"></i>
                {{ customerDetails.email_unsubscribed ? 'Re-subscribe For Emails' : 'Unsubscribe From Emails' }}
              </el-dropdown-item>
              <el-dropdown-item command="sms-unsubscribe">
                <i class="fa-regular fa-message-sms text-primary-600"></i>
                {{ customerDetails.sms_unsubscribed ? 'Re-subscribe For SMS' : 'Unsubscribe From SMS' }}
              </el-dropdown-item>
              <el-dropdown-item command="whatsapp-unsubscribe">
                <i class="fa-brands fa-whatsapp text-primary-600"></i>
                {{ customerDetails.whatsapp_unsubscribed ? 'Re-subscribe For WhatsApp' : 'Unsubscribe From WhatsApp' }}
              </el-dropdown-item>
              <el-dropdown-item command="archive" :divided="true">
                <i class="fa-regular fa-trash-can text-red-500"></i>
                Archive Customer
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <!-- Loader -->
      <div v-if="fetchingData" v-loading="true" style="background: white; height: 300px"></div>

      <!-- Header Body -->
      <el-row v-if="customerDetails">
        <!-- Customer Info -->
        <el-col :span="12" style="color: #777">
          <!-- Name -->
          <div style="font-size: 13px">
            <span v-if="customerDetails.name && customerDetails.name.trim()">{{ customerDetails.name }}</span>
            <span v-else>Unknown Customer</span>
          </div>
          <!-- Email -->
          <div style="font-size: 12px; margin-bottom: 3px; color: #777; margin-top: 3px">
            <div class="fontIconContainer">
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
            </div>
            <span v-if="customerDetails.email">
              {{ customerDetails.email }}
            </span>
            <span v-else>Not Available</span>
          </div>

          <!-- Mobile -->
          <div style="font-size: 12px; color: #777; margin-top: -3px">
            <div class="fontIconContainer">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </div>
            <span v-if="customerDetails.mobile">{{ customerDetails.mobile }} </span>
            <span v-else>Not Available</span>
          </div>
        </el-col>

        <!-- Timing Info -->
        <el-col :span="12" style="text-align: right; font-size: 13px; color: #777">
          <div class="fontIconContainer">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </div>
          {{ getReleventTime(customerDetails.last_event_at) }}
          <br />
          <div class="fontIconContainer">
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
          </div>
          Since {{ getCustomerDisplayDate(customerDetails.created_at) }}
          <div class="systemId">
            <el-tooltip class="item" effect="dark" content="Your system's customer id." placement="bottom-end">
              <span>
                <i class="fa fa-key" aria-hidden="true"></i> &nbsp;
                <template v-if="customerDetails.system_id">{{ customerDetails.system_id }}</template>
                <template v-else> Not Available </template>
              </span>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <!-- Row -->
    <div class="customerDetailsSection">
      <el-row v-if="customerDetails">
        <!-- Customer Properties -->
        <el-col :span="6" class="customerPropertiesPanel">
          <el-card>
            <div slot="header">Properties</div>

            <div class="customerProperties" v-if="customerDetails">
              <!-- No Props  -->
              <div class="noDetailsDiv" v-if="!customerDetails.data && customerDetails.data && Object.keys(customerDetails.data).length == 0 && Object.keys(customerCustomProperties).length == 0">No Properties Available</div>

              <!-- Customer Properties -->
              <div class="propertyContainer" :key="key" v-for="(value, key) in customerCustomProperties">
                <div class="key">{{ key.toUpperCase() }}</div>
                <div class="value">{{ value == null || value === undefined ? '--' : value }}</div>
              </div>
            </div>
          </el-card>
        </el-col>

        <!-- Right Side -->
        <el-col :span="18">
          <el-card class="campaignReachablityPanel">
            <div slot="header">Campaign Reachablity</div>

            <el-row v-if="customerDetails">
              <el-col :span="5" class="border-r text-center">
                <div class="text-xs pt-3">Email</div>
                <template v-if="customerDetails.email">
                  <i class="fa fa-check text-green-600"></i>
                </template>
                <template v-else>
                  <i class="fa fa-times text-red-500"></i>
                </template>

                <div class="pb-2" style="font-size: 10px">{{ customerDetails.email_unsubscribed ? 'Un-subscribed' : 'Subscribed' }}</div>
              </el-col>
              <el-col :span="5" class="border-r text-center">
                <div class="text-xs pt-3">SMS</div>
                <template v-if="customerDetails.mobile">
                  <i class="fa fa-check text-green-600"></i>
                </template>
                <template v-else>
                  <i class="fa fa-times text-red-500"></i>
                </template>

                <div class="pb-2" style="font-size: 10px">{{ customerDetails.sms_unsubscribed ? 'Un-subscribed' : 'Subscribed' }}</div>
              </el-col>
              <el-col :span="5" class="border-r text-center">
                <div class="text-xs pt-3">WhatsApp</div>
                <template v-if="customerDetails.mobile">
                  <i class="fa fa-check text-green-600"></i>
                </template>
                <template v-else>
                  <i class="fa fa-times text-red-500"></i>
                </template>

                <div class="pb-2" style="font-size: 10px">{{ customerDetails.whatsapp_unsubscribed ? 'Un-subscribed' : 'Subscribed' }}</div>
              </el-col>
              <el-col :span="5" class="border-r text-center">
                <div class="text-xs pt-3">Web Push</div>
                <template v-if="customerDetails.webpush_reachable">
                  <i class="fa fa-check text-green-600"></i>
                </template>
                <div v-else>
                  <i class="fa fa-times text-red-500"></i>
                </div>

                <div class="pb-2" style="font-size: 10px">&nbsp;</div>
              </el-col>
              <el-col :span="4" class="text-center">
                <div class="text-xs pt-3">Mobile Push</div>
                <template v-if="customerDetails.mobilepush_reachable">
                  <i class="fa fa-check text-green-600"></i>
                </template>
                <div v-else>
                  <i class="fa fa-times text-red-500"></i>
                </div>

                <div class="pb-2" style="font-size: 10px">&nbsp;</div>
              </el-col>
            </el-row>
          </el-card>

          <el-tabs v-model="selectedTab" class="detailsTab" type="border-card" @tab-click="onTabChange()">
            <!-- Profile Info -->
            <el-tab-pane label="Profile" name="profile" class="profileTab">
              <!-- Acquisition Card -->
              <el-card shadow="never">
                <div slot="header" class="clearfix">
                  <span>Aquisition Details </span>
                </div>

                <!-- Last Acquisition -->
                <div class="subHeader">First Time</div>
                <el-row class="row">
                  <el-col :span="6">
                    <div class="label">Referrer Host</div>
                    {{ customerDetails.initial_traffic_source }}
                  </el-col>
                  <el-col :span="6">
                    <div class="label">UTM Campaign</div>
                    {{ customerDetails.initial_utm_campaign ? customerDetails.initial_utm_campaign : '--' }}
                  </el-col>
                  <el-col :span="6">
                    <div class="label">UTM Source</div>
                    {{ customerDetails.initial_utm_source ? customerDetails.initial_utm_source : '--' }}
                  </el-col>
                  <el-col :span="6">
                    <div class="label">UTM Medium</div>
                    {{ customerDetails.initial_utm_medium ? customerDetails.initial_utm_medium : '--' }}
                  </el-col>
                </el-row>

                <!-- Last Acquisition -->
                <div class="subHeader secondSubHeader">From last session</div>
                <el-row class="row">
                  <el-col :span="6">
                    <div class="label">Referrer Host</div>
                    {{ customerDetails.last_traffic_source ? customerDetails.last_traffic_source : '--' }}
                  </el-col>
                  <el-col :span="6">
                    <div class="label">UTM Campaign</div>
                    {{ customerDetails.last_utm_campaign ? customerDetails.last_utm_campaign : '--' }}
                  </el-col>
                  <el-col :span="6">
                    <div class="label">UTM Source</div>
                    {{ customerDetails.last_utm_source ? customerDetails.last_utm_source : '--' }}
                  </el-col>
                  <el-col :span="6">
                    <div class="label">UTM Medium</div>
                    {{ customerDetails.last_utm_medium ? customerDetails.last_utm_medium : '--' }}
                  </el-col>
                </el-row>
              </el-card>

              <el-row>
                <!-- Location Info -->
                <el-col :span="12" class="locationInfo">
                  <el-card shadow="never">
                    <div slot="header" class="clearfix">
                      <span>Location Details </span>
                    </div>
                    <el-row>
                      <!-- Initial Location Info -->
                      <el-col :span="12">
                        <div class="subHeader">First Time</div>
                        <div class="label">City</div>
                        {{ customerDetails.initial_city ? customerDetails.initial_city : '--' }}
                        <div class="label">Country</div>
                        {{ customerDetails.initial_city ? customerDetails.initial_country : '--' }}
                        <div class="label">Region:</div>
                        {{ customerDetails.initial_region ? customerDetails.initial_region : '--' }}
                      </el-col>

                      <!-- Last Location Info -->
                      <el-col :span="12">
                        <div class="subHeader">Last Session</div>
                        <div class="label">City</div>
                        {{ customerDetails.last_city ? customerDetails.last_city : '--' }}
                        <div class="label">Country</div>
                        {{ customerDetails.last_country ? customerDetails.last_country : '--' }}
                        <div class="label">Region:</div>
                        {{ customerDetails.initial_region ? customerDetails.last_region : '--' }}
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>

                <!-- Other Props -->
                <el-col :span="12" class="deviceInfo">
                  <el-card shadow="never">
                    <div slot="header" class="clearfix">
                      <span>Other Info </span>
                    </div>
                    <el-row>
                      <!-- Initial Location Info -->
                      <el-col :span="12">
                        <div class="subHeader">First Time</div>
                        <div class="item">
                          <div class="label">Platform</div>
                          {{ customerDetails.initial_platform ? customerDetails.initial_platform : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Device</div>
                          {{ customerDetails.initial_device_manufacturer ? customerDetails.initial_device_manufacturer : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">OS</div>
                          {{ customerDetails.initial_os ? customerDetails.initial_os : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Browser</div>
                          {{ customerDetails.initial_browser ? customerDetails.initial_browser : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Mobile App Version</div>
                          {{ customerDetails.initial_app_version_name ? customerDetails.initial_app_version_name : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Device Model</div>
                          {{ customerDetails.initial_device_model ? customerDetails.initial_device_model : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Language</div>
                          {{ customerDetails.initial_language ? customerDetails.initial_language : '--' }}
                        </div>
                      </el-col>

                      <!-- Last Location Info -->
                      <el-col :span="12">
                        <div class="subHeader">Last Session</div>
                        <div class="item">
                          <div class="label">Platform</div>
                          {{ customerDetails.last_platform ? customerDetails.last_platform : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Device</div>
                          {{ customerDetails.last_device_manufacturer ? customerDetails.last_device_manufacturer : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">OS</div>
                          {{ customerDetails.last_os ? customerDetails.last_os : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Browser</div>
                          {{ customerDetails.last_browser ? customerDetails.last_browser : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Mobile App Version</div>
                          {{ customerDetails.last_app_version_name ? customerDetails.last_app_version_name : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Device Model</div>
                          {{ customerDetails.last_device_model ? customerDetails.last_device_model : '--' }}
                        </div>
                        <div class="item">
                          <div class="label">Language</div>
                          {{ customerDetails.last_language ? customerDetails.last_language : '--' }}
                        </div>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </el-tab-pane>

            <!-- Activites -->
            <el-tab-pane label="Activities" name="events" class="eventListTab">
              <div class="card-body eventContainer">
                <!-- Loader Div  -->
                <div class="noDetailsDiv" v-loading="true" v-if="fetchingEventList"></div>

                <!-- Event List -->
                <template v-if="!fetchingEventList">
                  <div class="border-b bg-gray-50 p-2 te">
                    <el-switch size="mini" v-model="onlyCustomEvents" @change="onCustomEventListFilterChange" active-text="Show Only Custom Events" inactive-text=""></el-switch>
                  </div>
                  <el-row class="eventItem" v-for="(event, index) in eventList" :key="index">
                    <!-- Event name -->
                    <el-col :span="14">
                      <div class="eventTime">
                        <span v-if="event.event_type == 'navigation'">Page Visit</span>
                        <span v-else-if="event.event_type == 'utm_visited'">UTM Visited</span>
                        <span v-else-if="event.event_type == 'mouse_click'">Mouse Click</span>
                        <span v-else-if="event.event_type == 'new_input'">Input</span>
                        <span v-else-if="event.event_type == 'app_launched'">App Launched</span>
                        <span v-else-if="event.event_type == 'app_installed'">App Installed</span>
                        <span v-else-if="event.event_type == 'app_uninstalled'">App Uninstalled</span>
                        <span v-else>{{ event.event_type }}</span>
                      </div>
                      <div class="customerActions">
                        {{ moment(event.created_at, 'x').format('Do MMM YYYY hh:mm:ss A') }}
                      </div>
                    </el-col>

                    <!-- Event Details  -->
                    <el-col :span="10" class="deviceOsInfo">
                      <el-popover placement="bottom" width="800" trigger="click">
                        <tree-view :data="getJsonValueIfAvailable(event.event_data)" :options="{ maxDepth: 2, rootObjectKey: 'Event Details' }"></tree-view>
                        <el-button size="mini" slot="reference">Detail</el-button>
                      </el-popover>
                    </el-col>
                  </el-row>
                </template>

                <!-- Bottom Pagination -->
                <div v-if="!fetchingEventList && eventList.length > 0" class="pagination pagination-bottom" style="border: none">
                  <el-pagination @size-change="onEventListPageSizeChange" @current-change="onHandleEventPageChange" :current-page.sync="currentEventListPage" :page-sizes="[5, 20, 50]" :page-size="currentEventListPageSize" layout="sizes, prev, pager, next, jumper" :total="eventCount"> </el-pagination>
                </div>
              </div>

              <!-- No Event Div  -->
              <div class="noDetailsDiv" v-if="!fetchingEventList && eventList.length == 0">No events found</div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style lang="scss" src="./customerDetails.scss"></style>

<script>
import CustomerDetailsComponent from './customerDetailsComponent';
export default CustomerDetailsComponent;
</script>
