import { fetchCustomerDetails, unsubscribeCustomer, archiveCustomer } from '../../services/customer';
import { fetchEventListByCustomerId } from '../../services/event';
import moment from 'moment';

export default {
  name: 'home',
  data() {
    return {
      moment: moment,
      selectedTab: 'profile',
      timestamp: null,

      //For customer details
      fetchingData: true,
      customerId: null,
      deviceId: null, // Used for fetching customer by device id.
      customerDetails: null,
      customerMetadata: null,
      customerCustomProperties: {},

      // for event list
      currentEventListPage: 1,
      currentEventListPageSize: 20,
      fetchingEventList: false,
      onlyCustomEvents: true,
      eventCount: null,
      eventList: []
    };
  },
  methods: {
    getEventDetailsLink(event) {
      return '/event/detail??id=' + event.id + '&timestamp=' + event.createdAt;
    },

    fetchData() {
      var params = {
        customerId: this.customerId,
        deviceId: this.deviceId,
        metadata: true
      };
      this.fetchingData = true;
      fetchCustomerDetails(params, this)
        .then((result) => {
          this.customerDetails = result.data.customer;
          this.customerId = this.customerDetails.id;
          this.customerCustomProperties = result.data.userProps;
          this.fetchingData = false;
        })
        .catch(() => {
          this.errorToast('Something is wrong, please contact support.');
          this.fetchingData = false;
        });
    },

    onTabChange() {
      if (this.selectedTab == 'events') {
        this.eventCount = null;
        this.fetchEventList();
      }
    },

    onCustomEventListFilterChange() {
      this.currentEventListPage = 1;
      this.eventCount = null;
      this.fetchEventList();
    },

    // Fetch event list
    fetchEventList() {
      let params = {
        customerId: this.customerId,
        pageNumber: this.currentEventListPage,
        pageSize: this.currentEventListPageSize,
        readTotal: this.eventCount == null,
        onlyCustomEvents: this.onlyCustomEvents
      };

      this.fetchingEventList = true;
      fetchEventListByCustomerId(params, this)
        .then((result) => {
          this.eventList = result.data.list;
          if (result.data.total) {
            this.eventCount = result.data.total;
          }
          this.fetchingEventList = false;
        })
        .catch((error) => {
          this.reportError(error);
          this.errorToast('Something went wrong!');
          this.fetchingEventList = false;
        });
    },

    onEventListPageSizeChange(val) {
      this.currentEventListPage = 1;
      this.eventCount = null;
      this.currentEventListPageSize = val;
      this.fetchEventList();
    },
    onHandleEventPageChange(val) {
      this.currentEventListPage = val;
      this.fetchEventList();
    },

    //#region -------------------- Start: Customer Actions
    onCustomerAction(action) {
      if (action == 'email-unsubscribe') this.unsubscribeCustomer('email');
      if (action == 'sms-unsubscribe') this.unsubscribeCustomer('sms');
      if (action == 'whatsapp-unsubscribe') this.unsubscribeCustomer('whatsApp');
      else if (action == 'archive') this.archiveCustomer();
    },

    async unsubscribeCustomer(channel) {
      let currentSubscriptionStatus = null;
      if (channel == 'email') currentSubscriptionStatus = this.customerDetails.email_unsubscribed;
      else if (channel == 'sms') currentSubscriptionStatus = this.customerDetails.sms_unsubscribed;
      else if (channel == 'whatsApp') currentSubscriptionStatus = this.customerDetails.whatsapp_unsubscribed;

      let changeStatusTo = currentSubscriptionStatus ? 0 : 1;

      if (!currentSubscriptionStatus) {
        this.$swal({
          title: `Unsubscribe from ${channel}?`,
          text: `Customer will not receive any further ${channel} being sent from Growlytics!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, Unsubscribe'
        }).then(async () => {
          await unsubscribeCustomer(this.customerId, channel, changeStatusTo);
          if (channel == 'email') this.customerDetails.email_unsubscribed = changeStatusTo;
          else if (channel == 'sms') this.customerDetails.sms_unsubscribed = changeStatusTo;
          else if (channel == 'whatsApp') this.customerDetails.whatsapp_unsubscribed = changeStatusTo;
          this.successToast('Customer un-subscribed.');
        });
      } else {
        await unsubscribeCustomer(this.customerId, channel, changeStatusTo);
        if (channel == 'email') this.customerDetails.email_unsubscribed = changeStatusTo;
        else if (channel == 'sms') this.customerDetails.sms_unsubscribed = changeStatusTo;
        else if (channel == 'whatsApp') this.customerDetails.whatsapp_unsubscribed = changeStatusTo;
        this.successToast('Customer re-subscribed.');
      }
    },

    archiveCustomer() {
      this.$swal({
        title: 'Archive customer?',
        text: `Archiving customer will remove all customer metadtaa like email and custom props, including system id. In short, customer will be converted to anonymous customer. You won't be able to rever this.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Archive'
      }).then(async () => {
        await archiveCustomer(this.customerId);
        this.successToast('Customer Archived');
        this.fetchData();
      });
    }

    ////#endregion ------ End: Customer Action
  },

  created() {
    this.customerId = this.$route.query.id;
    this.deviceId = this.$route.query.did;
    this.timestamp = this.$route.query.timestamp;
    this.fetchData();
  }
};
